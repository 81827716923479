<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-card-title class="headline">Cancel subscription for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          You can cancel this subscription at any time to prevent automatic renewal at the contract renewal date. 
        </p>
        <p>
          Any future billing cycles until the contract renewal will still be charged and invoiced.
        </p>

        <div v-if="licenseInfo && licenseInfo.contract_start_date && licenseInfo.contract_renewal_date">
          <h4>Annual Agreement</h4>
          <p>This agreement started at <strong>{{licenseInfo.contract_start_date | localDate('ymd')}}</strong> and will automatically renew at <strong>{{licenseInfo.contract_renewal_date | localDate('ymd')}}</strong> with another year (unless cancelled before this date).</p>
          <p>
            The next monthly payment will be charged and invoiced on <strong>{{licenseInfo.renewal_date | localDate('ymd')}}</strong>.
          </p>
        </div>
        <p v-else-if="licenseInfo && licenseInfo.start_date && licenseInfo.renewal_date">
          This subscription started at <strong>{{licenseInfo.start_date | localDate('ymd')}}</strong> and will automatically renew at <strong>{{licenseInfo.renewal_date | localDate('ymd')}}</strong> (unless cancelled before this date).
        </p>

        <v-alert v-if="licenseInfo && licenseInfo.cancellation_date" type="info">
          <p>This subscription was cancelled at <strong>{{licenseInfo.cancellation_date | localDate('ymd')}}</strong>. </p>
          
          <p v-if="licenseInfo.contract_renewal_date">
            Monthly billing will continue until the agreement end date ({{licenseInfo.contract_renewal_date | localDate('ymd')}}). All functionality will remain available until this date.
          </p>
        </v-alert>

        <p class="mt-8">
          <v-btn v-if="licenseInfo && licenseInfo.cancellation_date" color="primary" @click="resumeSubscription">Resume Subscription</v-btn>
          <v-btn v-else color="primary" @click="cancelSubscription">Cancel Subscription</v-btn>
        </p>

      </v-card-text>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import checkoutService from "@/services/checkoutService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "License",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      licenseInfo: null,
    };
  },
  async mounted() {
    await this.loadState();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadState();
    });
  },
  methods: {
    async getOrg(id) {
      await this.getLicenseInfo();
    },

    async loadState() {
      if (this.user) {
        await this.getOrg(this.$route.params.id);
      }
    },

    async getLicenseInfo() {
      if (this.org) {
        this.licenseInfo = (await orgManagerService.getLicenseStatus(this.org.id)).data;
      }
    },

    async cancelSubscription() {
      if (confirm('Are you sure you want to cancel this subscription?')) {
        const response = (await checkoutService.cancelOrgSubscription(this.org.id)).data;
        
        if (response.status === 'OK') {
          this.getLicenseInfo();
        }
        else {
          this.$helpers.toastResponse(this, response);
        }
      }
    },
    async resumeSubscription() {
      const response = (await checkoutService.resumeOrgSubscription(this.org.id)).data;
      
      if (response.status === 'OK') {
        this.getLicenseInfo();
      }
      else {
        this.$helpers.toastResponse(this, response);
      }
    },
    
  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: `Subscription`, disabled: true },
        { text: `Cancel`, disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    
    ...mapGetters({
      user: "user"
    })
  },
  watch: {

    org() {
      this.loadState();
    },
  }
};
</script>
<style lang="scss">
  
</style>

